.PlantingItemBrick {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: max-content;
  border: 1px solid darkgreen;
  margin: 10px;
  padding: 10px;
}
.PlantingItemBrick .buttons {
  display: flex;
  gap: 1rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9idWlsZF9mZWNlMmUwMi9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL3BsYW50aW5nL3Bsb3R0ZWQiLCJzb3VyY2VzIjpbIlBsYW50aW5nSXRlbUJyaWNrLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5QbGFudGluZ0l0ZW1CcmljayB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGdhcDogMXJlbTtcbiAgd2lkdGg6IG1heC1jb250ZW50O1xuICBib3JkZXI6IDFweCBzb2xpZCBkYXJrZ3JlZW47XG4gIG1hcmdpbjogMTBweDtcbiAgcGFkZGluZzogMTBweDtcblxuICAuYnV0dG9ucyB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBnYXA6IDFyZW07XG4gIH1cbn1cbiJdfQ== */