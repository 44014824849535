.PlantingItemModalInsideModal {
  min-height: 32rem;
}
.PlantingItemModalInsideModal .genetic-origin-chooser {
  padding: 5px;
  border: 1px solid black;
}
.PlantingItemModalInsideModal .filter-inputs {
  width: 100%;
  display: flex;
  gap: 20px;
}
.PlantingItemModalInsideModal .filter-inputs > * {
  flex: 1;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9idWlsZF9mZWNlMmUwMi9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL3BsYW50aW5nL3Bsb3R0ZWQiLCJzb3VyY2VzIjpbIlBsYW50aW5nSXRlbU1vZGFsLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTs7QUFDQTtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgXCIuLi8uLi8uLi8uLi9hc3NldHMvc3R5bGVzaGVldHMvbWl4aW5zL2J1dHRvbnNcIjtcblxuLlBsYW50aW5nSXRlbU1vZGFsSW5zaWRlTW9kYWwge1xuICBtaW4taGVpZ2h0OiAzMnJlbTtcbiAgLmdlbmV0aWMtb3JpZ2luLWNob29zZXIge1xuICAgIHBhZGRpbmc6IDVweDtcbiAgICBib3JkZXI6IDFweCBzb2xpZCBibGFjaztcbiAgfVxuXG4gIC5maWx0ZXItaW5wdXRzIHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGdhcDogMjBweDtcbiAgICA+ICoge1xuICAgICAgZmxleDogMTtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */