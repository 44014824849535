.ToggleButtons button {
  background: white;
  color: blue;
}
.ToggleButtons button.selected:not(:disabled) {
  background: blue;
  color: white;
}
.ToggleButtons button:disabled {
  background: lightgrey;
  color: darkgray;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9idWlsZF9mZWNlMmUwMi9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL2FwcGxpY2F0aW9uIiwic291cmNlcyI6WyJUb2dnbGVCdXR0b25zLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRTtFQUNBOztBQUNBO0VBQ0U7RUFDQTs7QUFHRjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuVG9nZ2xlQnV0dG9ucyB7XG4gIGJ1dHRvbiB7XG4gICAgYmFja2dyb3VuZDogd2hpdGU7XG4gICAgY29sb3I6IGJsdWU7XG4gICAgJi5zZWxlY3RlZDpub3QoOmRpc2FibGVkKSB7XG4gICAgICBiYWNrZ3JvdW5kOiBibHVlO1xuICAgICAgY29sb3I6IHdoaXRlO1xuICAgIH1cblxuICAgICY6ZGlzYWJsZWQge1xuICAgICAgYmFja2dyb3VuZDogbGlnaHRncmV5O1xuICAgICAgY29sb3I6IGRhcmtncmF5O1xuICAgIH1cbiAgfVxufVxuIl19 */