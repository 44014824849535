.OrgInput.OrgInputLabel {
  font-size: 1.5rem;
  font-weight: 500;
}
.OrgInput.single-row {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
}
.OrgInput.single-row > *:last-child {
  flex: 1;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9idWlsZF9mZWNlMmUwMi9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL2FwcGxpY2F0aW9uIiwic291cmNlcyI6WyJPcmdJbnB1dHMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFDQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLk9yZ0lucHV0IHtcbiAgJi5PcmdJbnB1dExhYmVsIHtcbiAgICBmb250LXNpemU6IDEuNXJlbTtcbiAgICBmb250LXdlaWdodDogNTAwO1xuICB9XG5cbiAgJi5zaW5nbGUtcm93IHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGdhcDogMTBweDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgID4gKjpsYXN0LWNoaWxkIHtcbiAgICAgIGZsZXg6IDE7XG4gICAgfVxuICB9XG59XG4iXX0= */